import React from "react";
import "./ContactForm.scss"; // Ensure you have the SCSS file for styles

const ContactForm = () => {
  return (
    <div className="container-fluid overflow-hidden py-5 px-lg-0">
      <div className="container contact-page py-5 px-lg-0">
        <div className="row g-5 mx-lg-0">
          <div
            className="col-md-6 contact-form wow fadeIn"
            data-wow-delay="0.1s"
          >
            <h6 className="text-secondary text-uppercase">Get In Touch</h6>
            <h1 className="mb-4">Contact For Any Query</h1>
            <p className="mb-4">
              We’re here to help! For any questions or assistance, please reach
              out using the contact information below or fill out our form.
            </p>
            <div className="bg-light p-4">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                      />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Your Email"
                      />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder="Subject"
                      />
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a message here"
                        id="message"
                        style={{ height: "100px" }}
                      ></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            className="col-md-6 pe-lg-0 wow fadeInRight"
            data-wow-delay="0.1s"
          >
            <div className="position-relative h-100">
              <iframe
                className="position-absolute w-100 h-100"
                style={{ border: "0", objectFit: "cover" }}
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d15955.549206902435!2d36.74828808167866!3d-1.2377949271625452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1snew%20kitisuru%20kusini%20lane!5e0!3m2!1sen!2ske!4v1726672608110!5m2!1sen!2ske"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
