import React from 'react';
import './PageTitle.scss';

const PageTitle = ({title,description}) => {
    return (
        <div className='page-title'>
            <div className="container">
                <h2>News & Articles</h2>
                <p>Stay updated with the latest innovations, industry insights, and company milestones at Euromax Shipping and Logistics. Discover how we're shaping the future of global logistics and delivering excellence every step of the way.</p>
            </div>
        </div> 
    );
};

export default PageTitle;