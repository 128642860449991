import React from "react";
import service1 from "../../assets/service/service-1.jpg";
import service2 from "../../assets/service/service-2.jpg";
import service3 from "../../assets/service/service-3.jpg";
import service4 from "../../assets/service/service-4.jpg";
import service5 from "../../assets/service/service-5.jpg";
import service6 from "../../assets/service/service-6.jpg";

const Features = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="text-secondary text-uppercase">Our Services</h6>
          <h1 className="mb-5">Explore Our Services</h1>
        </div>
        <div className="row g-4">
          <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={service1} alt="Air Freight" />
              </div>
              <h4 className="mb-3">Air Freight</h4>
              <p>
                Efficient air cargo services designed for speed and reliability,
                ensuring your goods reach any global destination swiftly.
              </p>
              <a className="btn-slide mt-2" href="#">
                <i className="fa fa-arrow-right"></i>
                <span>Read More</span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={service2} alt="Ocean Freight" />
              </div>
              <h4 className="mb-3">Ocean Freight</h4>
              <p>
                Cost-effective and reliable sea transport solutions for
                large-scale shipments, connecting you to major ports worldwide.
              </p>
              <a className="btn-slide mt-2" href="#">
                <i className="fa fa-arrow-right"></i>
                <span>Read More</span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={service3} alt="Road Freight" />
              </div>
              <h4 className="mb-3">Road Freight</h4>
              <p>
                Dependable and flexible road transport services, ensuring safe
                and timely delivery across local and regional routes.
              </p>
              <a className="btn-slide mt-2" href="#">
                <i className="fa fa-arrow-right"></i>
                <span>Read More</span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={service4} alt="Train Freight" />
              </div>
              <h4 className="mb-3">Train Freight</h4>
              <p>
                Eco-friendly and efficient rail transport solutions, ideal for
                moving bulk goods over long distances with precision and care.
              </p>
              <a className="btn-slide mt-2" href="#">
                <i className="fa fa-arrow-right"></i>
                <span>Read More</span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img
                  className="img-fluid"
                  src={service5}
                  alt="Customs Clearance"
                />
              </div>
              <h4 className="mb-3">Customs Clearance</h4>
              <p>
                Expert customs brokerage services, ensuring smooth and
                hassle-free clearance for your shipments at all borders.
              </p>
              <a className="btn-slide mt-2" href="#">
                <i className="fa fa-arrow-right"></i>
                <span>Read More</span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item p-4">
              <div className="overflow-hidden mb-4">
                <img
                  className="img-fluid"
                  src={service6}
                  alt="Warehouse Solutions"
                />
              </div>
              <h4 className="mb-3">Warehouse Solutions</h4>
              <p>
                Secure and efficient warehousing services, offering
                comprehensive storage and inventory management tailored to your
                needs.
              </p>
              <a className="btn-slide mt-2" href="#">
                <i className="fa fa-arrow-right"></i>
                <span>Read More</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
