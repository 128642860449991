import React from "react";
import "./ServiceBanner.scss"; // Make sure to create this file for styles

const ServicesBanner = () => {
  return (
    <div className="services-banner text-center py-5">
      <div className="overlay">
        <h1 className="text-white display-4">Our Premier Services</h1>
        <p className="text-white mt-3">
          Delivering Excellence in Every Aspect of Logistics
        </p>
      </div>
    </div>
  );
};

export default ServicesBanner;
