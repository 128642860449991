import React from "react";
import "./Symptoms.scss"; // Ensure you create a corresponding SCSS file for styles

const Symptoms = () => {
  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <div className="text-center pb-2">
          <h6 className="text-primary text-uppercase font-weight-bold">
            Our Services
          </h6>
          <h1 className="mb-4">Best Logistic Services</h1>
        </div>
        <div className="row pb-3">
          <div className="col-lg-3 col-md-6 text-center mb-5">
            <div className="d-flex align-items-center justify-content-center bg-primary mb-4 p-4">
              <i className="fa fa-2x fa-plane text-dark pr-3"></i>
              <h6 className="text-white font-weight-medium m-0">Air Freight</h6>
            </div>
            <p>
              We offer fast and reliable air freight services, ensuring your
              goods are delivered quickly to any destination worldwide. With our
              global network, we provide cost-effective and flexible air freight
              solutions for all types of cargo.
            </p>
            <a className="border-bottom text-decoration-none" href="#">
              Read More
            </a>
          </div>
          <div className="col-lg-3 col-md-6 text-center mb-5">
            <div className="d-flex align-items-center justify-content-center bg-primary mb-4 p-4">
              <i className="fa fa-2x fa-ship text-dark pr-3"></i>
              <h6 className="text-white font-weight-medium m-0">
                Ocean Freight
              </h6>
            </div>
            <p>
              Our ocean freight services provide secure and economical shipping
              for large volumes of goods. Whether it's full container loads
              (FCL) or less than container loads (LCL), we ensure timely
              delivery across all major sea routes.
            </p>
            <a className="border-bottom text-decoration-none" href="#">
              Read More
            </a>
          </div>
          <div className="col-lg-3 col-md-6 text-center mb-5">
            <div className="d-flex align-items-center justify-content-center bg-primary mb-4 p-4">
              <i className="fa fa-2x fa-truck text-dark pr-3"></i>
              <h6 className="text-white font-weight-medium m-0">
                Land Transport
              </h6>
            </div>
            <p>
              Our comprehensive land transport solutions cover local and
              regional deliveries with efficiency. From small packages to large
              shipments, we offer tailored road transport services to meet your
              unique needs.
            </p>
            <a className="border-bottom text-decoration-none" href="#">
              Read More
            </a>
          </div>
          <div className="col-lg-3 col-md-6 text-center mb-5">
            <div className="d-flex align-items-center justify-content-center bg-primary mb-4 p-4">
              <i className="fa fa-2x fa-store text-dark pr-3"></i>
              <h6 className="text-white font-weight-medium m-0">
                Cargo Storage
              </h6>
            </div>
            <p>
              Our secure storage facilities provide short-term and long-term
              options for your cargo. With modern warehousing solutions, we
              ensure your goods are stored in optimal conditions until they're
              ready for dispatch.
            </p>
            <a className="border-bottom text-decoration-none" href="#">
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Symptoms;
