import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shapeOne from "../../assets/carousel/carousel-1.jpg";
import shapeTwo from "../../assets/carousel/carousel-2.jpg";

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const Banner = () => {
  return (
    <div className="container-fluid p-0 pb-5">
      <Slider
        {...carouselSettings}
        className="header-carousel position-relative mb-5"
      >
        <div className="owl-carousel-item position-relative">
          <img className="img-fluid" src={shapeOne} alt="Carousel 1" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(6, 3, 21, .5)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Shipping, Security & Logistics Solution
                  </h5>
                  <h1 className="display-3 text-white animated slideInDown mb-4">
                    Place For Your{" "}
                    <span className="text-primary">Logistics</span> Solution
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                    Reliable Global Shipping and Logistics Solutions –
                    Delivering Excellence Across Industries.
                  </p>
                  <a
                    href="#"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Read More
                  </a>
                  <a
                    href="#"
                    className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                  >
                    Free Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-carousel-item position-relative">
          <img className="img-fluid" src={shapeTwo} alt="Carousel 2" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(6, 3, 21, .5)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Transport & Logistics Solution
                  </h5>
                  <h1 className="display-3 text-white animated slideInDown mb-4">
                    Place For Your{" "}
                    <span className="text-primary">Transport</span> Solution
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                    Reliable Global Shipping and Logistics Solutions –
                    Delivering Excellence Across Industries.
                  </p>
                  <a
                    href="#"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Read More
                  </a>
                  <a
                    href="#"
                    className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                  >
                    Free Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
