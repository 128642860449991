import React from "react";
import shapeFour from "../../assets/service/about.jpg";

const Services = () => {
  return (
    <div className="container-fluid overflow-hidden py-5 px-lg-0">
      <div className="container about py-5 px-lg-0">
        <div className="row g-5 mx-lg-0">
          <div
            className="col-lg-6 ps-lg-0 wow fadeInLeft"
            data-wow-delay="0.1s"
            style={{ minHeight: "400px" }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute img-fluid w-100 h-100"
                src={shapeFour} // Use the imported image here
                style={{ objectFit: "cover" }}
                alt="About Us"
              />
            </div>
          </div>
          <div
            className="col-lg-6 about-text wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <h6 className="text-secondary text-uppercase mb-3">About Us</h6>
            <h1 className="mb-5">Quick Transport and Logistics Solutions</h1>
            <p className="mb-5">
              From warehousing to freight forwarding, we ensure timely and
              efficient delivery, connecting your business to the world.
            </p>
            <div className="row g-4 mb-5">
              <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                <i className="fa fa-globe fa-3x text-primary mb-3"></i>
                <h5>Global Coverage</h5>
                <p className="m-0">
                  Wherever your destination, our extensive network guarantees
                  seamless transport and logistics services across the globe.
                </p>
              </div>
              <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                <i className="fa fa-shipping-fast fa-3x text-primary mb-3"></i>
                <h5>On Time Delivery</h5>
                <p className="m-0">
                  Count on us for fast, reliable, and punctual delivery,
                  ensuring your cargo reaches its destination as scheduled.
                </p>
              </div>
            </div>
            <a href="#" className="btn btn-primary py-3 px-5">
              Explore More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
