import React, { useState } from "react";
import Modal from "react-modal";
import aboutImg from "../../assets/about/about.jpg"; // Update the path as needed

// For accessibility
Modal.setAppElement("#root");

const Priority = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 pb-4 pb-lg-0">
            <img className="img-fluid w-100" src={aboutImg} alt="About Us" />
            <div className="bg-primary text-dark text-center p-4">
              <h3 className="m-0">Diplomatic Consignment</h3>
            </div>
          </div>
          <div className="col-lg-7">
            <h6 className="text-primary text-uppercase font-weight-bold">
              About Us
            </h6>
            <h1 className="mb-4">Trusted & Faster Logistic Service Provider</h1>
            <p className="mb-4">
              At Euromax Shipping and Logistics, we pride ourselves on being a
              reliable partner for all your transportation needs. With a
              commitment to speed, efficiency, and transparency, we offer
              seamless solutions across air, sea, and land freight. Our team of
              dedicated professionals ensures your cargo reaches its destination
              on time, every time, making us the trusted choice for businesses
              worldwide.
            </p>
            <div className="d-flex align-items-center pt-2">
              <button type="button" className="btn-play" onClick={openModal}>
                <span></span>
              </button>
              <h5 className="font-weight-bold m-0 ml-4">Play Video</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Video Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/DWRcNpR6Kdc"
            allow="autoplay"
            title="Video"
          />
        </div>
      </Modal>
    </div>
  );
};

export default Priority;
