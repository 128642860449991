import imgOne from '../../assets/blogs/1.png';
import imgTwo from '../../assets/blogs/2.png';
import imgThree from '../../assets/blogs/3.png'; 

const BlogData = [
    {
        'img': imgOne,
        'url': 'cutting-edge-technology-in-logistics',
        'title':'Cutting-Edge Technology in Logistics',
        'description':'Discover how innovative technologies like AI and automation are transforming global logistics operations.',
        'category': 'TECHNOLOGY'
    },
     {
        'img': imgTwo,
        'url':'importance-of-efficient-cargo-management',
        'title':'Importance of Efficient Cargo Management',
        'description':'Learn how proper cargo management helps streamline shipping processes and reduce delivery times.',
        'category': 'CARGO'
    },
    {
        'img': imgThree,
        'url':'sustainable-shipping-practices',
        'title':'Sustainable Shipping Practices for the Future',
        'description':'Explore eco-friendly shipping practices and how they are shaping the future of the logistics industry.',
        'category': 'SUSTAINABILITY'
    }
];

export default BlogData;
